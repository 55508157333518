import React from "react"
import { graphql } from "gatsby"

import Layout from "components/Layout"

import {
  Hero,
  About,
  Support,
  Apartments,
  Map,
  News,
} from "page_components/home"

const Home = ({ data, location }) => {
  const allApartmentsPromo = [
    ...data.allWpApartmentSkyTrust.nodes,
    ...data.allWpApartmentRezydencjaCentrum.nodes,
    ...data.allWpApartmentKrakowskaRogatka.nodes,
  ]
  const smartEstatePromo =
    data.allWp.nodes[0].smartEstate.acfEstateSettings.promotedApartments
  const allPortfolioCommercial = data.allWpPortfolioCommercial.nodes

  return (
    <Layout
      location={location}
      seo={{
        title: "Nowe mieszkania na sprzedaż od dewelopera",
        description:
          "Trust Investment S.A. to deweloper realizujący projekty w sektorze nieruchomości mieszkaniowych oraz komercyjnych na terenie całej Polski.",
      }}
      isHome
    >
      <Hero />
      <Apartments
        allApartmentsPromo={allApartmentsPromo}
        smartEstatePromo={smartEstatePromo}
      />
      <About />
      <Map allPortfolioCommercial={allPortfolioCommercial} />
      <Support />
      <News />
    </Layout>
  )
}

export const query = graphql`
  query {
    allWpApartmentSkyTrust(sort: { fields: title, order: ASC }) {
      nodes {
        id
        title
        uri
        slug
        acfApartment {
          investment
          promo
          apartmentNumber
          buildingNumber
          area
          floor
          location
          price
          priceForSquareMeter
          roomsnumber
          status
          visualization2d {
            sourceUrl
          }
        }
      }
    }
    allWpApartmentRezydencjaCentrum(sort: { fields: title, order: ASC }) {
      nodes {
        id
        title
        uri
        slug
        acfApartment {
          investment
          investmentBuilding
          promo
          apartmentNumber
          buildingNumber
          area
          floor
          floorSecond
          location
          price
          priceForSquareMeter
          roomsnumber
          status
          visualization2d {
            sourceUrl
          }
        }
      }
    }
    allWpApartmentKrakowskaRogatka(sort: { fields: title, order: ASC }) {
      nodes {
        id
        title
        uri
        slug
        acfApartment {
          investment
          investmentBuilding
          promo
          apartmentNumber
          buildingNumber
          area
          floor
          floorSecond
          location
          price
          priceForSquareMeter
          roomsnumber
          status
          visualization2d {
            sourceUrl
          }
        }
      }
    }
    allWp {
      nodes {
        smartEstate {
          acfEstateSettings {
            promotedApartments {
              investment
              apartments {
                view {
                  localFile {
                    publicURL
                  }
                }
                data {
                  number
                  rooms
                  floor
                  area
                }
              }
            }
          }
        }
      }
    }
    allWpPortfolioCommercial(sort: { fields: date, order: DESC }) {
      nodes {
        id
        title
        uri
        slug
        featuredImage {
          node {
            localFile {
              publicURL
            }
          }
        }
        acfPortfolioCommercial {
          city
          map {
            latitude
            longitude
          }
        }
      }
    }
  }
`

export default Home
